<template>
  <b-form>
    <b-row>
      <b-col>
        <b-card title="Aktarım Veri Girişi Alanı">
          <b-form-textarea
            v-model="content"
            placeholder="Yemek listesini bu alana yapıştırın"
            rows="10"
          />
          <b-row>
            <b-col>
              <b-alert
                show
                variant="warning"
                class="m-1"
              >
                <div class="alert-body text-center">
                  Tarih alanı <b>YYYY-AA-GG</b> formatında olmalıdır
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Yemek Listesi">
          <template v-if="importList.length > 0">
            <b-table-simple

              small
              striped
            >
              <b-thead>
                <b-tr>
                  <b-th>Yemek</b-th>
                  <b-th>Ay</b-th>
                  <b-th>Yıl</b-th>
                  <b-th>Tarih</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(item,key) in importList"
                  :key="key"
                >
                  <b-td>{{ item.title }}</b-td>
                  <b-td>{{ item.month }}</b-td>
                  <b-td>{{ item.year }}</b-td>
                  <b-td>{{ item.mdate }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-row>
              <b-col>
                <small class="text-center mt-1">{{ importList.length }} satır veri oluşturuldu.</small>
              </b-col>
            </b-row>
          </template>
          <b-alert
            v-else
            show
            variant="info"
            class="m-2"
          >
            <div class="alert-body text-center">
              Aktarılacak veriniz yok. 👏
            </div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      <b-spinner
        v-if="submitStatus"
        small
        class="mr-1"
        label="Aktarılıyor"
      />
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormTextarea,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd, BAlert, BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'Add',
  components: {
    BAlert,
    BForm,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormTextarea,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTbody,
    BTd,
    BSpinner,
  },
  data() {
    return {
      submitStatus: false,
      content: null,
    }
  },
  computed: {
    saveStatus() {
      return this.$store.getters['meals/getMealSaveStatus']
    },
    importList() {
      return this.$store.getters['meals/importList']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
    content(val) {
      if (val) {
        this.dataList = []
        this.tableFields = []
        this.$store.commit('meals/SET_IMPORT_LIST', [])
        const dataList = this.content.split('\n')
        dataList.forEach((e, index) => {
          const cols = e.split('\t')
          if (index > 0) {
            this.dataList.push(cols)
            this.importList.push({
              title: cols[0],
              month: cols[1],
              year: cols[2],
              mdate: cols[3],
            })
          }
        })
      }
    },
  },
  created() {
    this.$store.commit('meals/SET_IMPORT_LIST', [])
    this.getItems()
  },
  methods: {
    getItems() {
      this.$store.dispatch('meals/itemsList')
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      if (this.importList.length > 0) {
        this.submitStatus = true
        this.$store.dispatch('meals/mealBatchSave', this.importList)
      } else {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Aktarım listesi boş veya uygun olmayan veri içeriyor.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
